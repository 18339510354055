.animate-header {
  top: -70px;
  transition: all 0.3s ease-in-out;
}

.animate-header-visible {
  top: 0;
  transition: all 0.3s ease-in-out;
}

@font-face {
font-family: '__aktivGroteskExt_4e4c8a';
src: url(/download/_next/static/media/bead20a386401bcf-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 700;
font-style: italic;
}

@font-face {
font-family: '__aktivGroteskExt_4e4c8a';
src: url(/download/_next/static/media/bead20a386401bcf-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 700;
font-style: normal;
}@font-face {font-family: '__aktivGroteskExt_Fallback_4e4c8a';src: local("Arial");ascent-override: 76.24%;descent-override: 21.64%;line-gap-override: 0.00%;size-adjust: 130.78%
}.__className_4e4c8a {font-family: '__aktivGroteskExt_4e4c8a', '__aktivGroteskExt_Fallback_4e4c8a'
}.__variable_4e4c8a {--aktiv-grotesk-extended: '__aktivGroteskExt_4e4c8a', '__aktivGroteskExt_Fallback_4e4c8a'
}

@font-face {
font-family: '__aktivGrotesk_195c82';
src: url(/download/_next/static/media/d337b64d71e9cee5-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 400;
font-style: italic;
}

@font-face {
font-family: '__aktivGrotesk_195c82';
src: url(/download/_next/static/media/d337b64d71e9cee5-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 400;
font-style: normal;
}

@font-face {
font-family: '__aktivGrotesk_195c82';
src: url(/download/_next/static/media/0eb75ff7f0bfb397-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 600;
font-style: italic;
}

@font-face {
font-family: '__aktivGrotesk_195c82';
src: url(/download/_next/static/media/0eb75ff7f0bfb397-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 600;
font-style: normal;
}

@font-face {
font-family: '__aktivGrotesk_195c82';
src: url(/download/_next/static/media/efb3ae3ab4e630a5-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 700;
font-style: normal;
}

@font-face {
font-family: '__aktivGrotesk_195c82';
src: url(/download/_next/static/media/efb3ae3ab4e630a5-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 700;
font-style: italic;
}@font-face {font-family: '__aktivGrotesk_Fallback_195c82';src: local("Arial");ascent-override: 97.43%;descent-override: 27.65%;line-gap-override: 0.00%;size-adjust: 102.33%
}.__className_195c82 {font-family: '__aktivGrotesk_195c82', '__aktivGrotesk_Fallback_195c82'
}.__variable_195c82 {--aktiv-grotesk: '__aktivGrotesk_195c82', '__aktivGrotesk_Fallback_195c82'
}

